import React, {useCallback, useEffect, useMemo, useState} from "react";
import {usePersistentState} from "../util/usePersistentState";
import {useApi} from "../api/APIContext";
import {getDefaultDay} from "./util/datetime";
import {Project, Task, TASK_QUANTITY_TYPE_OTHER} from "../api/dto";
import {useRealtimeUpdates} from "../realtime/RealtimeContext";
import {useInterval} from "../util/useInterval";
import moment from "moment";

interface PlannedProject {
  id: string,
  name: string,
  team: number|null,
  timeslot: number,
  duration: number,
  date: Date|null
  color?: "blue"|"red"|"green"|"yellow"|"purple"|"pink"|"orange"
}
interface Block<T> {
  id: string
  slot: number
  duration: number
  canvasId: string
  details: T
}
export const ALL_TEAMS_CATEGORY = 'Alle teams'

export interface MousePosition {
  x: number,
  y: number,
}
export interface PlannerContextType {
  // Project/task data
  projectMap: Map<string, Project>
  taskMap: Map<string, Task>
  projectTasksMap: Map<string, Task[]>
  getPlannedTasksForDay: (date: Date) => Task[]
  isTaskSaving: (taskId: string) => boolean

  // Floating stuff
  day: Date,
  setDay: React.Dispatch<React.SetStateAction<Date>>
  onMouseDown: (project: string, mode: "move"|"resize_start"|"float"|"resize_end") => void
  plannedProjects: PlannedProject[]
  area: "world"|"drawer"|string
  setArea: (area: "world"|"drawer"|string) => void
  slot: number
  setSlot: (slot: number) => void
  mode: "idle"|"float"|"dropped"|"move"|"resize_start"|"resize_end",
  floatingTask: Task|null,
  mousePos: MousePosition|null

  // Notifications
  notifications: Notification[]
  addNotification: (notification: Omit<Notification, 'id'|'createdAt'>) => void
  dismissNotification: (notification: Notification) => void

  // Category
  category: string,
  setCategory: React.Dispatch<React.SetStateAction<string>>

  // Canvas
  getBlocksInCanvas: (date: Date, teamId: string) => Block<Task>[]
  bufferSize: 'w-72'|'w-80'|'w-96'
  setBufferSize: (size: 'w-72'|'w-80'|'w-96') => void

  // Focus
  focussedTask: string|null
  focusTask: (task: Task) => void
  clearFocussedTask: () => void

  // Scale
  scaleConfig: {
    scale: number,
    slotSize: number,
    canvasWidth: number,
    sidebarWidth: number,
    containerWidth: number,
  },
  setScale: (scale: number) => void
}
export interface PlannerContextProps {
  children: React.ReactNode
}

const PlannerContext = React.createContext<PlannerContextType>({} as PlannerContextType);


export interface Notification {
  id: string
  title: string
  description: string
  level: "info"|"warning"|"error"
  createdAt: Date
}
export function PlannerContextProvider(props: PlannerContextProps) {
  const {projects,  tasks, planTask, unPlanTask, taskToBuffer, resetLocalChanges, contextDate, setContextDate, quantityTypes} = useApi()
  const [day, setDay] = usePersistentState<Date>('planner.selected_date', getDefaultDay())
  const [currentPos, setCurrentPos] = useState<MousePosition|null>(null)
  const [mode, setMode] = useState<"idle"|"float"|"move"|"dropped"|"resize_start"|"resize_end">("idle")
  const [selectedTask, setSelectedTask] = useState<string | null>(null)
  const [actionStartPos,  setActionStartPos] = useState<MousePosition|null>(null)
  const [area, setArea] = useState<"world"|"drawer"|string>("world")
  const [slot, setSlot] = useState<number>(0)

  const [scale, setScale] = useState(1)

  const [focussedTask, setFocussedTask] = useState<string|null>(null)

  const [notifications, setNotifications] = useState<Notification[]>([])
  const addNotification = useCallback((notification: Omit<Notification, 'id'|'createdAt'>) => {
    setNotifications(old => [...old, {
      ...notification,
      id: Math.random().toString(36).slice(2, 9),
      createdAt: new Date(),
    }])
  }, [])
  const dismissNotification = useCallback((notification: Notification) => {
    setNotifications(old => old.filter(n => n.id !== notification.id))
  }, [])

  const { dateSelected } = useRealtimeUpdates()

  useEffect(() => {
    dateSelected(day)
  }, [day]);
  useInterval(() => {
    dateSelected(day, true)
  }, 3000)

  useEffect(() => {
    if (contextDate && day && Math.abs(moment(day).diff(contextDate, 'days')) > 90) {
      setContextDate(day)
    }
  }, [day, contextDate])

  const scaleConfig = useMemo(() => {
    const sidebarWidth = 250
    const canvasWidth = 1100 * scale
    const slotSize = 25 * scale
    const containerWidth = sidebarWidth + canvasWidth
    return {
      scale,
      slotSize,
      canvasWidth,
      sidebarWidth,
      containerWidth,
    }
  }, [scale])

  /**
   * Planning
   */
  const getPlannedTasksForDay = useCallback((date: Date): Task[] => {
    return tasks.filter(t => {
      return t.startAt && t.durationMinutes && t.teamId && t.startAt.getFullYear() === date.getFullYear() && t.startAt.getMonth() === date.getMonth() && t.startAt.getDate() === date.getDate()
    }, [])
  }, [tasks])
  const projectMap = useMemo(() => {
    return projects.reduce((map, project) => {
      map.set(project.id, project)
      return map
    }, new Map<string,Project>())
  }, [projects])
  const taskMap = useMemo(() => {
    return tasks.reduce((map, task) => {
      map.set(task.id, task)
      return map
    }, new Map<string,Task>())
  }, [tasks])
  const projectTasksMap = useMemo(() => {
    return tasks.reduce((map, task) => {
      if (!task.projectId) {
        return map
      }
      if (!map.has(task.projectId)) {
        map.set(task.projectId, [])
      }
      map.get(task.projectId)?.push(task)
      return map
    }, new Map<string,Task[]>())
  }, [tasks])

  const [stagedBlockChanges, setStagedBlockChanges] = useState(new Map<string, Partial<Block<Task>&{unPlan?:true}>>())
  const blocks = useMemo(() => {
    return tasks.filter(t => {
      if (stagedBlockChanges.get(t.id)?.unPlan) {
        return false
      }
      const isFullyInitialized = t.startAt && t.teamId && t.durationMinutes
      const isBeingCreated = stagedBlockChanges.has(t.id)
      return isFullyInitialized || isBeingCreated
    }).map(task => {
      if (!task.startAt && stagedBlockChanges.has(task.id)) {
        // In this case, the task is being created
        return stagedBlockChanges.get(task.id)!
      }
      const originalBlock = {
        id: task.id,
        slot: timeToSlot(task.startAt!),
        duration: durationMinutesToSlots(task.durationMinutes!),
        canvasId: getCanvasId(task.startAt!, task.teamId!),
        details: task,
      }
      if (!stagedBlockChanges.has(task.id)) {
        return originalBlock
      }
      const stagedBlock = stagedBlockChanges.get(task.id)!
      return {
        ...originalBlock,
        ...stagedBlock,
      }
    }).filter(block => {
      return block.details?.startAt && block.details?.teamId && block.details?.durationMinutes && block.canvasId
    }) as Block<Task>[]
  }, [tasks, stagedBlockChanges])

  const blocksByCanvas = useMemo(() => {
    return blocks.reduce((map, block) => {
      map.set(block.canvasId, [...(map.get(block.canvasId) ?? []), block])
      return map
    }, new Map<string, Block<Task>[]>())
  }, [blocks])
  const blockById = useMemo(() => {
    return blocks.reduce((map, block) => {
      map.set(block.id, block)
      return map
    }, new Map<string, Block<Task>>())
  }, [blocks])

  const getBlocksInCanvas = useCallback((date: Date, teamId: string) => {
    return blocksByCanvas.get(getCanvasId(date, teamId)) ?? []
  }, [blocksByCanvas])

  const updateBlock = (id: string, fields: Partial<Block<Task>>|null) => {
    setStagedBlockChanges(oldMap => {
      const newMap = new Map(oldMap.entries())
      if (fields === null) {
        newMap.set(id, {unPlan: true})
        return newMap
      }
      if (!newMap.has(id)) {
        newMap.set(id, fields)
        return newMap
      }
      const oldFields = newMap.get(id)!
      newMap.set(id, {...oldFields, unPlan: undefined, ...fields})
      return newMap
    })
  }

  const inTeamArea = useMemo(() => area.includes(':'), [area])
  const inBufferArea = useMemo(() => area.includes('buffer-'), [area])
  const floatingTask = useMemo(() => {
    const selected = selectedTask ? taskMap.get(selectedTask) : undefined
    const isPlanned = selectedTask && blockById.has(selectedTask)
    if ((!isPlanned && area !== 'drawer' && mode === "float") || (area === "drawer" && mode === "move")) {
      return selected ?? null
    }
    return null
  }, [taskMap, selectedTask, blockById, area, mode])

  const [saving, setSaving] = useState(false)
  const isTaskSaving = useCallback((taskId: string): boolean => {
    return stagedBlockChanges.has(taskId) && saving
  }, [stagedBlockChanges, saving])
  useEffect(() => {
    if (mode === "idle" && stagedBlockChanges.size > 0 && !saving) {
      setSaving(true)
      // There are unsaved changes, save them
      let promises: Promise<string|null>[] = []
      for (const taskId of stagedBlockChanges.keys()) {
        const block = blockById.get(taskId)
        if (!block) {
          const task = taskMap.get(taskId)!
          if (! task) continue
          // We should check if the block has a date, even though it has no team. This case is for tasks that are in the buffer.
          const stagedBlockChange = stagedBlockChanges.get(taskId)!
          if (stagedBlockChange.details?.startAt && !stagedBlockChange.details?.teamId) {
            // This is a task that should be in the buffer
            promises.push(taskToBuffer(task, stagedBlockChange.details.startAt).then(() => {
              return taskId
            }))
          } else {
            promises.push(unPlanTask(task).then(() => {
              return taskId
            }))
          }
        } else {
          const {teamId, date} = parseCanvasId(block.canvasId)
          date.setHours(Math.floor(block.slot/4 + 6), (block.slot*15) % 60,0,0)
          promises.push(planTask(block.details, teamId, date, block.duration * 15).then(() => {
            return taskId
          }).catch(e => {
            let description = e.message
            addNotification({
              title: "Fout bij opslaan",
              description: description,
              level: "error",
            })
            setStagedBlockChanges(oldMap => {
              return new Map([...oldMap.entries()].filter(([id]) => id !== taskId))
            })
            throw e
          }))
        }

      }
      // Update the staged changed by deleting only the ones that were successfully updated
      Promise.all(promises).then(async (updatedIds) => {
        await new Promise(resolve => setTimeout(resolve, 100))
        setStagedBlockChanges(oldMap => {
          return new Map([...oldMap.entries()].filter(([id]) => !updatedIds.includes(id)))
        })
        await new Promise(resolve => setTimeout(resolve, 100))
      }).catch(async (e) => {
        await resetLocalChanges()
        throw e
      }).finally(() => setSaving(false))
    }
  }, [stagedBlockChanges, blockById, planTask, taskMap, mode, saving, resetLocalChanges]);
  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      setCurrentPos({x: e.clientX, y: e.clientY})
    }
    // console.log('registering event listeners')
    window.addEventListener('mouseup', onMouseUp)
    window.addEventListener('mousemove', onMouseMove)
    return () => {
      // console.log('deregistering event listeners')
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [])

  const onMouseUp = () => {
    setMode(oldMode => {
      if (oldMode === 'float') {
        return 'dropped'
      }
      return 'idle'
    })
    setActionStartPos(null)
  }
  const onMouseDown = useCallback((task: string, mode: "move"|"float"|"resize_start"|"resize_end") => {
    setSelectedTask(task)
    setMode(mode)
    setActionStartPos(currentPos)
  }, [currentPos])

  const willTaskCollideWithOtherBlocks = useCallback((task: Task, teamId: string, date: Date, newTimeslot: number, newDuration: number): boolean => {
    const otherBlocks = getBlocksInCanvas(date, teamId)
      .filter(b => b.id !== task.id) // Exclude the requested block
    return otherBlocks.some(b => {
      return b.slot <= (newTimeslot + newDuration - 1) && (b.slot + b.duration - 1) >= newTimeslot
    })
  }, [getBlocksInCanvas])

  const getDefaultDuration = useCallback((task: Task) => {
    const type = task.quantityType ?? TASK_QUANTITY_TYPE_OTHER
    const quantityType = quantityTypes.find(qt => qt.name === type)
    if (quantityType?.canPredict()) {
      const duration = Math.round(quantityType?.predict(task.quantityAmount!)! / 15) * 15
      addNotification({
        title: "Taak automatisch ingeschat",
        description: `Deze taak is geschat op ${duration/60} uur`,
        level: "info",
      })
      return duration
    }
    return 120
  }, [quantityTypes, addNotification])

  const dropTaskIfPossible = useCallback((taskId: string, areaType: 'team'|'buffer'|'world') => {
    const task = taskMap.get(taskId)
    if (! task) {
      return
    }
    console.info('Attempting to drop task', task)
    if (areaType === 'team') {
      const {teamId, date} = parseCanvasId(area)
      for (let duration = durationMinutesToSlots(task.durationMinutes ?? getDefaultDuration(task)); duration >= 2; duration--) {
        const defaultTimeslot = slot > 0 && slot < 44 - duration ? slot : 4
        for (let timeslot = defaultTimeslot; timeslot < (44 - duration); timeslot++) {
          const willCollide = willTaskCollideWithOtherBlocks(task, teamId, date, timeslot, duration)
          if (task && !willCollide) {
            updateBlock(task.id, {
              details: {
                ...task,
                startAt: date,
                durationMinutes: duration * 15,
                teamId: teamId,
              },
              id: task.id,
              slot: timeslot,
              duration: duration,
              canvasId: area,
            })
            return
          }
        }
      }
    } else if (areaType === 'world') {
      const update = {
        details: {
          ...task,
          startAt: null,
          durationMinutes: null,
          teamId: null,
        },
        id: task.id,
        slot: 4,
        duration: getDefaultDuration(task),
        canvasId: area,
      }
      updateBlock(task.id, update)
    } else {
      const date = parseBufferDate(area)
      const update = {
        details: {
          ...task,
          startAt: date,
          durationMinutes: null,
          teamId: null,
        },
        id: task.id,
        slot: 4,
        duration: getDefaultDuration(task),
        canvasId: area,
      }
      updateBlock(task.id, update)
    }
  }, [area, slot, taskMap, willTaskCollideWithOtherBlocks, updateBlock, getDefaultDuration])

  useEffect(() => {
    if (mode === 'dropped') {
       if (selectedTask && inBufferArea) {
        dropTaskIfPossible(selectedTask, 'buffer')
      } else if (selectedTask && inTeamArea) {
        dropTaskIfPossible(selectedTask, 'team')
      } else if (selectedTask && area === 'world') {
         dropTaskIfPossible(selectedTask, 'world')
       }
      setMode('idle')
      setSelectedTask(null)
    }
  }, [mode, selectedTask, dropTaskIfPossible, inTeamArea, inBufferArea, area])

  useEffect(() => {
    if (mode === 'idle') {
      return
    }
    if (mode === "move") {
      const block = selectedTask ? blockById.get(selectedTask) : undefined
      if (! block) {
        return
      }
      if (area !== block.canvasId) {
        // This should be sufficient?
        if (selectedTask) {
          console.debug({area, block, selectedTask})
          // console.log('Switching to floating mode')
          updateBlock(selectedTask, null)
          setMode('float')
        }
        // console.log("The block is on the move, but not in its original canvas")
        // if (inTeamArea) {
        //   const {teamId, date} = parseCanvasId(area)
        //   if (willTaskCollideWithOtherBlocks(block.details, teamId, date, block.slot, block.duration)) {
        //     console.log("Block is moving in team area, but will collide with other blocks, aborting")
        //     return
        //   }
        //   if (selectedTask) {
        //     console.log("Block is moving in team area, but will collide with other blocks, aborting")
        //     updateBlock(selectedTask, {canvasId: area})
        //   }
        //   setMode('idle')
        // } else if(block.canvasId !== null) {
        //   if (selectedTask) {
        //     console.log('Switching to floating mode')
        //     updateBlock(selectedTask, null)
        //     setMode('float')
        //   }
        // }
      }
      if (selectedTask && currentPos && actionStartPos) {
        if (Math.abs(currentPos.x - actionStartPos.x) > scaleConfig.slotSize) {
          // console.log(currentPos.x, actionStartPos.x, currentPos.x - actionStartPos.x)
          if (block) {
            const delta = Math.round((currentPos.x - actionStartPos.x) / scaleConfig.slotSize)
            const newTimeslot = Math.min(44 - block.duration, Math.max(0, block.slot + delta))
            const {teamId, date} = parseCanvasId(block.canvasId)
            if (willTaskCollideWithOtherBlocks(block.details, teamId, date, newTimeslot, block.duration)) {
              // console.log('Attempting to move block within canvas, but will collide with other blocks, aborting')
              return
            }
            // console.log('Can move block within canvas, updating timeslot from ', block.slot, 'to', newTimeslot, 'with delta', delta)
            updateBlock(selectedTask, {slot: newTimeslot})
            // console.log('Resetting action start pos from', actionStartPos.x, 'to', actionStartPos.x + delta * scaleConfig.slotSize)
            setActionStartPos(start => start ? {...start, x: start.x + delta * scaleConfig.slotSize} : null)
          }
        }
      }
    }
    if (mode === "resize_start" && selectedTask && currentPos && actionStartPos) {
      if (Math.abs(currentPos.x - actionStartPos.x) > scaleConfig.slotSize) {
        const block = selectedTask ? blockById.get(selectedTask) : undefined
        if (block) {
          let delta = Math.round((currentPos.x - actionStartPos.x) / scaleConfig.slotSize)
          delta = Math.max(-block.slot, delta)
          delta = Math.min(block.duration - 2, delta)
          if (delta === 0) {
            return
          }
          const newDuration = block.duration - delta
          const newTimeslot = block.slot + delta
          const {teamId, date} = parseCanvasId(block.canvasId)
          if (willTaskCollideWithOtherBlocks(block.details, teamId, date, newTimeslot, newDuration)) {
            return
          }
          updateBlock(selectedTask, {slot: newTimeslot, duration: newDuration})
          setActionStartPos(start => start ? {...start, x: start.x + delta * scaleConfig.slotSize} : null)
        }
      }
    }
    if (mode === "resize_end" && selectedTask && currentPos && actionStartPos) {
      if (Math.abs(currentPos.x - actionStartPos.x) > scaleConfig.slotSize) {
        const block = selectedTask ? blockById.get(selectedTask) : undefined
        if (block) {
          let delta = Math.round((currentPos.x - actionStartPos.x) / scaleConfig.slotSize)
          delta = Math.max(-block.duration+2, delta)
          delta = Math.min(44-block.duration-block.slot, delta)
          if (delta === 0) {
            return
          }
          const newDuration = block.duration + delta
          const {teamId, date} = parseCanvasId(block.canvasId)
          if (willTaskCollideWithOtherBlocks(block.details, teamId, date, block.slot, newDuration)) {
            return
          }
          updateBlock(selectedTask, {duration: newDuration})
          setActionStartPos(start => start ? {...start, x: start.x + delta * scaleConfig.slotSize} : null)
        }
      }
    }
  }, [currentPos, mode, selectedTask, blockById, actionStartPos, scaleConfig])
  const [category, setCategory] = usePersistentState<string>('team_category', ALL_TEAMS_CATEGORY)
  const [bufferSize, setBufferSize] = usePersistentState<'w-72'|'w-80'|'w-96'>('buffer_size', 'w-72')
  const context: PlannerContextType = {
    getPlannedTasksForDay,
    isTaskSaving,
    projectMap,
    taskMap,
    projectTasksMap,
    day,
    setDay,
    onMouseDown,
    plannedProjects: [],
    setArea,
    slot,
    setSlot,
    mode,
    area,
    floatingTask,
    mousePos: currentPos,
    getBlocksInCanvas,
    scaleConfig,
    setScale,
    notifications,
    addNotification,
    dismissNotification,
    category,
    setCategory,
    bufferSize,
    setBufferSize,
    focussedTask,
    focusTask: (task: Task) => {
      if (task.startAt)  {
        setDay(task.startAt)
      }
      setFocussedTask(task.id);
    },
    clearFocussedTask: () => setFocussedTask(null),
  }
  return <PlannerContext.Provider value={context}>{props.children}</PlannerContext.Provider>;
}
export function usePlanner(): PlannerContextType {
  return React.useContext(PlannerContext)
}

export function getCanvasId(date: Date, teamId: string): string {
  return `${teamId}:${date.getFullYear()}-${String(date.getMonth()+1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
}

export function parseCanvasId(canvasId: string): {teamId: string, date: Date} {
  const [teamId, dateString] = canvasId.split(':')
  return {teamId, date: new Date(dateString)}
}

export function parseBufferDate(canvasId: string): Date {
  const dateString = canvasId.split('buffer-')[1]
  return new Date(dateString)
}

export function timeToSlot(time: Date): number {
  const minuteOfDay = time.getHours() * 60 + time.getMinutes()
  return Math.floor(minuteOfDay / 15) - 24 // We start the day at 6:00AM
}
export function slotToTime(timeslot: number, date?: Date): Date {
  const hour = Math.floor(timeslot / 4) + 6
  const minute = (timeslot % 4) * 15
  const dateTime = date ?? new Date()
  dateTime.setHours(hour, minute, 0, 0)
  return dateTime
}
export function durationMinutesToSlots(duration: number): number {
  return Math.ceil(duration / 15)
}

export function timeSlotToString(timeslot: number): string {
  const hour = Math.floor(timeslot / 4) + 6
  const minute = (timeslot % 4) * 15
  return `${hour}:${String(minute).padStart(2, '0')}`
}