import {useRoles} from "./AuthProvider";

export function usePermissions() {
  const roles = useRoles()
  return {
    canAddEmployees: roles.includes('manager'),
    canEditEmployees: roles.includes('manager'),
    canEditEmployeesWorkdays: roles.includes('manager'),
    canResetEmployeesPassword: roles.includes('manager'),
    canEditEmployeesMetadata: roles.includes('manager'),
    canEditEmployeesRoles: roles.includes('manager')
  }
}