import React, {useEffect, useState} from "react";
import {Employee} from "../../api/dto";
import {useModal} from "../../components/layout/ModalProvider";
import {EnableEmployeeModal} from "../../modals/EnableEmployeeModal";
import {DisableEmployeeModal} from "../../modals/DisableEmployeeModal";
import {EmployeeWorkordersModal} from "../../modals/EmployeeWorkordersModal";
import {UpdateEmployeeModal} from "../../modals/UpdateEmployeeModal";
import {Button} from "../../components/form/Button";
import {faAdd, faEdit, faEye, faTimes,faReceipt , faTrash} from "@fortawesome/free-solid-svg-icons";
import {LoginEditor} from "./LoginEditor";
import {RoosterEditor} from "./RoosterEditor";
import {ColorEditor} from "./ColorEditor";
import {usePermissions} from "../../auth/permissions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RoleEditor} from "./RoleEditor";

export const EmployeeDetails: React.FC<{employee: Employee}> = ({employee}) => {
    const permissions = usePermissions()
    const enableEmployeeModal = useModal({title: 'Zet medewerker naar actief', body: <EnableEmployeeModal employee={employee} />})
    const disableEmployeeModal = useModal({title: 'Zet medewerker naar non-actief', body: <DisableEmployeeModal employee={employee} />})
    const employeeWorkordersModal = useModal({title: 'Werkbonnen voor Medewerker', body: <EmployeeWorkordersModal employee={employee} />})
    const editEmployeeNameModal = useModal({title: 'Wijzig naam', body: <UpdateEmployeeModal employee={employee} updateName={true} updateEmail={false} updatePassword={false} />})
    return <div className={"border border-slate-100 rounded-lg p-2 text-sm flex flex-wrap "}>
        <div className={"border border-slate-100 rounded p-3 mx-1 my-1"}>
            <h2 className={'font-medium mb-2'}>Rooster</h2>
            <div>
                <RoosterEditor employee={employee} />
            </div>
        </div>

      {permissions.canResetEmployeesPassword && <>
        <div className={"border border-slate-100 rounded p-3 mx-1 my-1"}>
          <h2 className={'font-medium mb-2'}>Inloggen</h2>
          <div className={"w-64"}>
            <LoginEditor employee={employee}/>
          </div>
        </div>
      </>}

      {permissions.canEditEmployeesMetadata && <>
        <div className={"border border-slate-100 rounded p-3 mx-1 my-1"}>
          <h2 className={'font-medium mb-2'}>Indicatiekleur</h2>
          <div className={"w-64"}>
            <ColorEditor employee={employee}/>
          </div>
        </div>
        <div className={"border border-slate-100 rounded p-3 mx-1 my-1"}>
            <h2 className={'font-medium mb-2'}>Acties</h2>
            <div className={'flex flex-col items-stretch w-64 space-y-1'}>
                <Button type={'secondary'} size={'sm'} text={'Bekijk werkbonnen'} icon={faReceipt} onClick={() => employeeWorkordersModal.open()} />
              {permissions.canEditEmployees && <>
                <Button type={'secondary'} size={'sm'} text={'Naam wijzigen'} icon={faEdit} onClick={() => editEmployeeNameModal.open()} />
                <Button type={employee.enabled ? "danger" : "secondary"} size={'sm'} text={employee.enabled ? 'Naar non-actief' : "Activeren"} icon={employee.enabled ? faTimes : faAdd} onClick={() => (employee.enabled ? disableEmployeeModal : enableEmployeeModal).open()} />
              </>}
              </div>
        </div>
      </>}

      {permissions.canEditEmployeesRoles && <>
        <div className="border border-slate-100 rounded p-3 mx-1 my-1 flex-col w-64">
          <h2 className="font-medium mb-2">Toegang</h2>
          <RoleEditor employee={employee}/>
        </div>
      </>}

    </div>
}