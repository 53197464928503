import React, {FC, ReactNode, useCallback, useEffect, useMemo, useRef} from "react";
import {Supplier, Task, Team} from "../../api/dto";
import {getCanvasId, MousePosition, slotToTime, timeSlotToString, usePlanner} from "../PlannerContext";
import {useThemes} from "../util/theming";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faCircleNotch,
  faGripLinesVertical,
  faReceipt,
  faWarning
} from "@fortawesome/free-solid-svg-icons";
import {TaskContextMenu} from "./TaskContextMenu";
import {useApi} from "../../api/APIContext";
import {HoverHint} from "../../components/content/HoverHint";
import {isMousePositionInElement} from "../util/positioning";
import {useModal} from "../../components/layout/ModalProvider";
import {AddTaskModal} from "../../modals/AddTaskModal";
import {useTaskTypes} from "../hooks/useTaskTypes";
import {FocussedTaskDialog} from "./FocussedTaskDialog";

export const PlanCanvas: FC<{team: Team, date: Date}> = props => {
  const N_SLOTS = 44
  const LOCATION = getCanvasId(props.date, props.team.id)
  const ref = useRef<HTMLDivElement>(null)
  const {quantityTypes} = useApi()
  const {onMouseDown, scaleConfig, focussedTask, area, setArea, slot, setSlot, mousePos, mode, getBlocksInCanvas, projectMap, isTaskSaving} = usePlanner()
  const {getTheme} = useThemes()
  useEffect(() => {
    if (mousePos && ref.current) {
      const inElement = isMousePositionInElement(mousePos, ref.current)
      if (inElement &&  area !== LOCATION) {
        setArea(LOCATION)
      } else if (! inElement && area === LOCATION) {
        setArea("world")
      }
      const currentSlot = getMouseSlot(mousePos, ref.current, N_SLOTS, 4)
      if (slot !== currentSlot) {
        setSlot(currentSlot)
      }
    }
  }, [ref, mousePos, area, slot])

  const tasksInCanvas = getBlocksInCanvas(props.date, props.team.id)
  const shouldDim = focussedTask !== null && !tasksInCanvas.map(x => x.details.id).includes(focussedTask)
  const children: ReactNode[] = []
  const judgeTask = useCallback((task: Task): {type: "long"|"short"|"fine", suggestion: number} => {
    const type = quantityTypes.find(qt => qt.name === task.quantityType)
    if (task.quantityAmount === null) return {type: "fine", suggestion: 0}
    if (task.durationMinutes === null) return {type: "fine", suggestion: 0}
    if (!type) return {type: "fine", suggestion: 0}
    if (!type.canPredict()) return {type: "fine", suggestion: 0}
    const predictedDuration = Math.round(type.predict(task.quantityAmount)! / 15) * 15
    const diff = (predictedDuration - task.durationMinutes) / task.durationMinutes
    if (diff > 0.2) return {type: "short", suggestion: predictedDuration}
    if (diff < -0.2) return {type: "long", suggestion: predictedDuration}
    return {type: "fine", suggestion: 0}
  }, [quantityTypes])
  const {getTaskName, getTaskColor} = useTaskTypes()
  for (let i=0; i<N_SLOTS; i++) {
    const blockStart = tasksInCanvas.find(t => t.slot === i) // Current slot is start of task
    if (blockStart) {
      const isSaving = isTaskSaving(blockStart.id)
      const theme = getTheme(getTaskColor(blockStart.details) ?? undefined, blockStart.details.isInvoiced)
      const project = blockStart.details.projectId ? projectMap.get(blockStart.details.projectId) : undefined
      const judgement = judgeTask(blockStart.details)
      children.push(<div className={`flex-1 ${theme.bg} my-1 ml-1 rounded-l-lg relative flex items-stretch`}>
        <button disabled={isSaving} className={`${theme.lightText} ${theme.buttonHover} text-base p-1 rounded-l-lg ${isSaving ? 'cursor-wait' : 'cursor-ew-resize'} opacity-0 hover:opacity-100`} onMouseDown={() => !isSaving && onMouseDown(blockStart.id, "resize_start")}><FontAwesomeIcon icon={faGripLinesVertical} /></button>
        {judgement.type !== "fine" && <div className={"h-4 w-14 absolute -top-3 left-4 bg-amber-600 rounded border border-white text-white px-1 z-10"}>
          <HoverHint hint={`Geschatte duur: ${judgement.suggestion/60} uur`}>
            <div className={"flex items-center justify-center"}>
              <FontAwesomeIcon icon={faWarning} className={"mr-1"} />
              <span className={"font-medium"}>{judgement.type === "long" ? "RUIM" : "KRAP"}</span>
            </div>
          </HoverHint>
        </div>}
        {blockStart.details.id === focussedTask && <FocussedTaskDialog />}
        {Object.values(blockStart.details.todos).some(x => !x) && <div className={`h-4 w-24 absolute -top-3 ${judgement.type !== "fine" ? 'left-20' : 'left-4'} bg-green-600 rounded border border-white text-white px-1 z-10`}>
          <div className={"flex items-center justify-center"}>
            <FontAwesomeIcon icon={faCheckSquare} className={"mr-1"} />
            <span className={"font-medium"}>ACTIE VEREIST</span>
          </div>
        </div>}
        <div className={`absolute top-0 left-0 h-full mx-4 hover:z-10 ${theme.text} text-base overflow-ellipsis flex flex-col-reverse items-stretch overflow-hidden hover:overflow-visible`} style={{width: `calc(${(blockStart.duration - 1)*scaleConfig.slotSize}px - 1rem)`}}>
          <div className={`h-10 flex items-stretch`}>
            <TaskContextMenu task={blockStart.details} project={project} className={`flex-1 flex flex-col ${isSaving ? 'cursor-wait' : 'cursor-move'} ${theme.bgHover} hover:border-2 ${theme.borderHover} mx-1 hover:-ml-[calc(0.25rem+2px)] hover:-mb-[1px] hover:px-2 hover:shadow-lg rounded justify-center`} onLeftMouseDown={() => !isSaving && onMouseDown(blockStart.id, "move")}>
              <h3 className={`flex items-center`}>
                <LeverancierStatus task={blockStart.details} />
                {blockStart.details.isSent && <HoverHint hint={`Werkbon verstuurd`}>
                  <div className={"flex items-center justify-center"}>
                    <FontAwesomeIcon icon={faReceipt} className={"text-xs mr-1 opacity-90"}/>
                  </div>
                </HoverHint>}
                <span className={`overflow-ellipsis break-words text-sm ${theme.bg} rounded whitespace-nowrap`}>{blockStart.details.isInvoiced&&project===undefined&&<span className={"font-bold text-white"}>&euro;&nbsp;&nbsp;</span>}{getTaskName(blockStart.details)}</span>
              </h3>
              {['resize_start', 'resize_end', 'move'].includes(mode) ? <>
                <p className={`${theme.lightText} text-xs overflow-ellipsis ${theme.bg} rounded whitespace-nowrap`}>{timeSlotToString(blockStart.slot)} - {timeSlotToString(blockStart.slot + blockStart.duration)}</p>
              </> : <>
                {project && <p className={`${theme.lightText} text-xs overflow-ellipsis ${theme.bg} rounded whitespace-nowrap`}>{blockStart.details.isInvoiced&&<span className={`font-bold ${theme.text}`}>&euro;&nbsp;</span>}#{project.projectNumber}, {project.deliveryDetails.city} - {project.description}</p>}
              </>}
            </TaskContextMenu>
          </div>
        </div>
      </div>)
      continue;
    }
    const blockEnd = tasksInCanvas.find(t => (t.slot + t.duration - 1) === i) // Current slot is end of task
    if (blockEnd) {
      const isSaving = isTaskSaving(blockEnd.id)
      const theme = getTheme(getTaskColor(blockEnd.details) ?? undefined, blockEnd.details.isInvoiced)
      children.push(
        <div className={`flex-1 ${theme.bg} -ml-[2px] my-1 mr-1 relative rounded-r-lg flex justify-end items-stretch`}>
          {isSaving && <div className={`absolute top-1/2 right-full -translate-y-1/2 h-4 w-4 rounded-full z-20 ${theme.bg} ${theme.lightText} flex items-center justify-center`}>
            <FontAwesomeIcon icon={faCircleNotch} className={"animate-spin text-base"} />
          </div>}
          <button disabled={isSaving} className={`${theme.lightText} ${theme.buttonHover} text-base p-1 rounded-r-lg ${isSaving ? 'cursor-wait' : 'cursor-ew-resize'} opacity-0 hover:opacity-100`} onMouseDown={() => !isSaving && onMouseDown(blockEnd.id, "resize_end")}><FontAwesomeIcon icon={faGripLinesVertical} /></button>
        </div>
      )
      continue;
    }
    const blockMiddle = tasksInCanvas.find(t => t.slot < i && (t.slot + t.duration - 1) > i) // Current slot is middle of task
    if (blockMiddle) {
      const theme = getTheme(getTaskColor(blockMiddle?.details) ?? undefined, blockMiddle?.details.isInvoiced)
      children.push(<div className={`flex-1 ${theme.bg} -ml-[2px] my-1`}></div>)
      continue;
    }
    children.push(<AddTaskButton slot={slot} team={props.team} date={props.date} />)
  }

  return <div style={{width: `${scaleConfig.canvasWidth}px`}} className={`flex border ${mode === "float" ? `border-blue-700` : 'border-slate-200'} rounded-r ${area === LOCATION && 'bg-blue-50'} ${shouldDim ? 'opacity-20' : ''}`} ref={ref}>
    {children.map((c, i) => <div key={i} className={`flex-1 text-[0.6rem] ${slot === i && area === LOCATION && 'bg-blue-100'} text-slate-400 ${i>0 && 'border-l'} ${i%4===0 ? 'border-slate-200' : 'border-slate-100'} flex flex-col`}>{c}</div>)}
  </div>
}

function getMouseSlot(mousePosition: MousePosition, element: HTMLElement, nSlots: number, defaultSlot: number): number {
  const {left, width} = element.getBoundingClientRect()
  const slotWidth = width / nSlots
  const x = mousePosition.x - left
  if (x < 0 || x > width) return defaultSlot
  return Math.floor(x / slotWidth);
}

function LeverancierStatus(props: {task: Task}) {
  const {suppliers} = useApi()
  const supplierMap = useMemo(() => {
    const map = new Map<string, Supplier>()
    suppliers.forEach(s => map.set(s.id, s))
    return map
  }, [suppliers])
  if (props.task.suppliers.length === 0) {
    return <></>
  }
  let style = ''
  switch (props.task.suppliersStatus) {
    case "todo": style = 'border border-red-200 mr-1 h-3 w-3 bg-red-500 rounded'; break;
    case "in progress": style = 'border border-amber-200 mr-1 h-3 w-3 bg-amber-500 rounded'; break;
    case "done": style = 'border border-green-200 mr-1 h-3 w-3 bg-green-500 rounded'; break;
  }
  return <div className={`${style} relative group`}>
    <span className={"hidden group-hover:flex absolute top-[100%] left-[50%] z-10 -translate-x-1/2 transform w-64  justify-center items-center"}>
      <span className={"bg-black text-white text-xs px-2 mt-1 py-1 rounded relative shadow"}>
        <div>
          {props.task.suppliers.map((s, i) => {
            const style = {
              'red': 'bg-red-500',
              'orange': 'bg-amber-500',
              'green': 'bg-green-500',
            }[s.status] ?? 'bg-blue-500'
            const supplier = supplierMap.get(s.supplierId) as Supplier
            return <div key={i} className={"flex items-center"}>
              <div className={`${style} h-2 w-2 mr-2 rounded-full`}></div>
              <div>{supplier.name} {s.description ? `(${s.description})` : ''}</div>
            </div>
          })}
        </div>
        {/*Arrow*/}
        <div className={"absolute top-0 left-[50%] -translate-x-1/2 -translate-y-1/2 rotate-45 h-2 w-2 bg-black"}></div>
      </span>
    </span>
  </div>
}

const AddTaskButton: FC<{slot: number, team: Team, date: Date}> = props => {
  const {planTask, taskToBuffer} = useApi()
  const date = slotToTime(props.slot, props.date)
  const {addNotification} = usePlanner()
  const onSubmit = async (tasks: Task[]) => {
    const [task] = tasks
    await planTask(task, props.team.id, date, 60)
      .catch(async () => {
        await taskToBuffer(task, date)
        addNotification({
          title: "Niet ingepland",
          description: "De taak is niet ingepland omdat er geen ruimte is. De taak is toegevoegd aan het overschot vak.",
          level: "warning",
        })
      })
  }
  const addTaskModal = useModal({title: "Taak toevoegen", body: <AddTaskModal onSubmit={onSubmit} />})
  return <button className={"w-full h-full cursor-copy"} onClick={addTaskModal.open}></button>
}