import React, {FC, useEffect, useMemo, useState} from "react";
import {ReactKeycloakProvider, useKeycloak} from "@react-keycloak/web";
import keycloak from "./keycloak";
import {UnauthenticatedPlaceholder, UnauthorizedPlaceholder} from "../components/layout/UnauthenticatedPlaceholder";
import {AuthenticatedLayout} from "../components/layout/AuthenticatedLayout";

export const AuthMiddleware: FC<{children: React.ReactNode}> = ({children}) => {
  const {keycloak} = useKeycloak()
  const roles = useMemo(() => keycloak.tokenParsed?.realm_access?.roles ?? [], [keycloak.tokenParsed])
  if (! keycloak.authenticated) {
    return <UnauthenticatedPlaceholder />
  }
  if (! roles.includes("office")) {
    return <UnauthorizedPlaceholder />
  }
  return <AuthenticatedLayout>{children}</AuthenticatedLayout>
}

export function useRoles(): string[] {
  const {keycloak} = useKeycloak()
  return useMemo(() => keycloak.tokenParsed?.realm_access?.roles ?? [], [keycloak.tokenParsed])
}

interface AuthContextType {}
export const AuthContext = React.createContext<AuthContextType>({} as AuthContextType)
export const AuthProvider: FC<{children: React.ReactNode}> = ({children}) => {
  /**
   * This is a dirty workaround for an imperfection in react-keycloak.
   * It does not re-render the app when the token is refreshed. Usually, this is not a problem because the user is
   * clicking through the application triggering re-renders. But when the user is idle (e.g. on the deployments page on
   * a second monitor), the token will expire and the HTTP requests will start failing.
   * By setting a timer that triggers a re-render every 10 seconds, we can work around this issue. Because incrementing
   * the useState value will trigger a re-render of <ReactKeycloakProvider>, which will handle the token refresh.
   */
  const [, setDirtyWorkaroundCounter] = useState(0)
  useEffect(() => {
    const timer = setInterval(() => {
      keycloak.updateToken(120)
      setDirtyWorkaroundCounter(prev => prev + 1)
    }, 60000)
    return () => clearInterval(timer)
  }, [])

  const onTokens = (tokens: {idToken?: string, refreshToken?: string, token?: string}) => {
    setToken(tokens.token ?? null)
  }

  return <AuthContext.Provider value={{}}>
    <ReactKeycloakProvider onTokens={onTokens} authClient={keycloak} initOptions={{onLoad: 'login-required'}}>
      {children}
    </ReactKeycloakProvider>
  </AuthContext.Provider>
}

function setToken(token: string|null): void {
  if (! window?.localStorage) {
    console.error("localStorage not available")
    return
  }
  if (token === null) {
    window.localStorage.removeItem('session')
  } else {
    window.localStorage.setItem("session", token)
  }
}
export function getToken(): string|null {
  if (! window?.localStorage) {
    console.error("localStorage not available")
    return null
  }
  return window.localStorage.getItem("session")
}