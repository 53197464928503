import React, {FC} from "react";

interface PillSelectProps {
  options: { [key: string]: string }
  value: string
  onChange: (value: string) => void
}
export const PillSelect: FC<PillSelectProps> = props => {
  return <div className={""}>
    <ul className={"flex flex-wrap my-4"}>
      {Object.entries(props.options).map(([tabKey, tabName], index) => {
        return <li key={index} className={"mb-2 mr-2"}>
          <button type={"button"} onClick={() => props.onChange(tabKey)} className={`h-8 text-sm rounded-lg font-medium flex items-center px-3 border ${props.value === tabKey ? 'bg-brand-100 text-brand-900 border-brand-500' : 'bg-slate-100 hover:bg-slate-200 cursor-pointer text-slate-900 border-transparent'}`}>
            {tabName}
          </button>
        </li>
      })}
    </ul>
  </div>
}