import React, {FC} from "react";
import {ModalFooter, useModalControls} from "../components/layout/ModalProvider";
import {Project, Task, Workorder} from "../api/dto";
import {ValidationError} from "../api/api";
import {useApi} from "../api/APIContext";
import {DataTable} from "../components/data/DataTable";
import moment from "moment";
import {usePlanner} from "../planning/PlannerContext";
import {useNavigate} from "react-router-dom";

export const ProjectDetailsModal: FC<{project: Project, goToDay: (day: Date) => void, focusTask: (task: Task) => void}> = (props) => {

  return <div>
    {/* Information*/}
    <ProjectOverview project={props.project} />
    {/* Tabs */}
    <ProjectTabs project={props.project} focusTask={props.focusTask} goToDay={props.goToDay} />
  </div>
}
const ProjectOverview = ({project}: {project: Project}) => {
  return <div className={'grid grid-cols-1 lg:grid-cols-2 gap-4'}>
    <div>
      <div className={'text-lg font-bold mb-2'}>Projectgegevens</div>
      <div className={'border border-slate-200 px-4 py-3 rounded-lg grid grid-cols-3'}>
        <div className={'font-medium text-slate-800'}>Nr.:</div>
        <div className={'col-span-2'}>{project.projectNumber}</div>
        <div className={'font-medium text-slate-800'}>Omschrijving:</div>
        <div className={'col-span-2'}>{project.description}</div>
      </div>
    </div>
    <div>
      <div className={'text-lg font-bold mb-2'}>Aflever informatie</div>
      <div className={'border border-slate-200 px-4 py-3 rounded-lg grid grid-cols-3'}>
        <div className={'font-medium text-slate-800'}>Naam:</div>
        <div className={'col-span-2'}>{project.projectNumber}</div>
        <div className={'font-medium text-slate-800'}>E-mail:</div>
        <div className={'col-span-2'}>{project.deliveryDetails?.email}</div>
        <div className={'font-medium text-slate-800'}>Telefoon:</div>
        <div className={'col-span-2'}>{project.deliveryDetails?.phone}</div>
        <div className={'font-medium text-slate-800'}>Adres:</div>
        <div className={'col-span-2'}>
          <div>{project.deliveryDetails?.address}</div>
          <div>{project.deliveryDetails?.zipcode} {project.deliveryDetails?.city}</div>
        </div>
      </div>
    </div>
  </div>
}
const ProjectTabs = ({project, goToDay, focusTask}: {project: Project, goToDay: (day: Date) => void, focusTask: (day: Task) => void}) => {
  const {workorders, tasks} = useApi()
  const projectTasks = tasks.filter(t => t.projectId === project.id)
  const projectWorkorders = workorders.filter(w => w.projectNumber === project.projectNumber)
  const tabs = [
    {name: `Taken (${projectTasks.length})`, content: <ProjectTasksTab project={project} tasks={projectTasks} focusTask={focusTask} />},
    {name: `Werkbonnen (${projectWorkorders.length})`, content: <ProjectWorkordersTab project={project} workorders={projectWorkorders} goToDay={goToDay} />},
  ]
  const [tab, setTab] = React.useState<number>(0)
  return <div>
    <div className={'-mx-4 border-b-[3px] border-slate-300 flex px-4'}>
      {tabs.map((t, i) => <button className={`rounded-t h-12 font-medium text-sm uppercase px-4 border-b-[3px] ${i === tab ? 'text-blue-800 bg-blue-50 border-blue-800' : 'text-slate-800 border-slate-300 hover:text-blue-800'} -mb-[3px]`} key={i} onClick={() => setTab(i)}>{t.name}</button>)}
    </div>
    <div className={'h-[50vh] overflow-y-scroll -mx-4'}>
      <div className={'my-4 mx-4'}>
        {tabs[tab].content}
      </div>
    </div>
  </div>
}

const ProjectTasksTab = (props: {project: Project, tasks: Task[], focusTask: (day: Task) => void}) => {
  const {teams, quantityTypes} = useApi()
  const navigate = useNavigate()
  const {close} = useModalControls()
  const sortedTasks = props.tasks.sort((a, b) => {
    if (a.startAt === null && b.startAt === null) return 0;
    if (a.startAt === null) return -1;
    if (b.startAt === null) return 1;
    return b.startAt.getTime() - a.startAt.getTime();
  })
  return <div className={''}>
    <DataTable keyProperty={'id'} data={sortedTasks} compact={true} placeholder={'Geen taken'} columns={[
      {header: 'Hoeveelheid', property: 'quantityAmount', transform: (_, t) => {
          if (t.quantityAmount !== null && t.quantityType !== null) {
            const type = quantityTypes.find(qt => qt.id === t.quantityType)
            if (type?.hideArea) {
              return type.name
            }
            return `${t.quantityAmount}m² ${type?.name ?? t.quantityType}`;
          } else {
            return '';
          }
        }},
      {header: 'Omschrijving', property: 'description'},
      {header: 'Gepland', property: 'startAt', transform: (v: Date|null) => <span className={v && moment(v).isBefore(new Date) ? 'text-slate-500' : 'font-medium'}>{v !== null ? moment(v).format('DD-MM-YYYY') : '-'}</span>},
      {header: 'Team', property: 'teamId', transform: (v: string|null) => teams.find(t => t.id === v)?.name ?? ''},
    ]} onClickRow={(row) => {
      if (row.startAt !== null) {
        props.focusTask(row)
        navigate('/')
        close()
      }
    }} />
  </div>
}

const ProjectWorkordersTab = (props: {project: Project, workorders: Workorder[], goToDay: (day: Date) => void}) => {
  const {teams, quantityTypes} = useApi()
  const navigate = useNavigate()
  const {close} = useModalControls()
  const sortedWorkorders = props.workorders.sort((a, b) => {
    if (a.startAt === undefined && b.startAt === undefined) return 0;
    if (a.startAt === undefined) return -1;
    if (b.startAt === undefined) return 1;
    return b.startAt.getTime() - a.startAt.getTime();
  })
  return <div className={''}>
    <DataTable keyProperty={'id'} data={sortedWorkorders} compact={true} placeholder={'Geen werkbonnen'} columns={[
      {header: 'Hoeveelheid', property: 'quantityAmount', transform: (_, t) => {
          if (t.quantityAmount !== null && t.quantityType !== null) {
            const type = quantityTypes.find(qt => qt.id === t.quantityType)
            if (type?.hideArea) {
              return type.name
            }
            return `${t.quantityAmount}m² ${type?.name ?? t.quantityType}`;
          } else {
            return '';
          }
        }},
      {header: 'Omschrijving', property: 'description'},
      {header: 'Status', property: 'isFinished', transform: (v?: boolean) => {
          if (v) {
            return <div className={'flex'}>
              <div
                className={'px-2 h-5 text-xs rounded-full text-green-700 bg-green-100 font-medium uppercase flex items-center justify-start'}>
                <div className={'h-2 w-2 rounded-full bg-green-600 mr-2'}></div>
                <span>Afgerond</span>
              </div>
            </div>
          } else {
            return <div className={'flex'}>
              <div
                className={'px-2 h-5 text-xs rounded-full text-amber-700 bg-amber-100 font-medium uppercase flex items-center justify-start'}>
                <div className={'h-2 w-2 rounded-full bg-amber-600 mr-2'}></div>
                <span>Verstuurd</span>
              </div>
            </div>
          }
        }
      },
      {header: 'Gepland', property: 'startAt', transform: (v: Date|undefined) => <span className={v && moment(v).isBefore(new Date) ? 'text-slate-500' : 'font-medium'}>{v !== null ? moment(v).format('DD-MM-YYYY') : '-'}</span>},
      {header: 'Team', property: 'teamId', transform: (v: string|undefined) => teams.find(t => t.id === v)?.name ?? ''},
    ]}
               onClickRow={(row) => {
      if (row.startAt !== undefined) {
        props.goToDay(row.startAt)
        navigate('/workorders')
        close()
      }
    }}
    />
  </div>
}