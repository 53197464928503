import {Employee} from "../../api/dto";
import React, {FC, useState} from "react";
import {Button} from "../../components/form/Button";
import {ValidationError} from "../../api/api";
import {useApi} from "../../api/APIContext";

export const RoleEditor: FC<{employee: Employee}> = ({employee}) => {
  const {editRoles} = useApi()
  const [isEmployee, setIsEmployee] = useState<boolean>(employee.roles.some(r => r === "employee"))
  const [isOffice, setIsOffice] = useState<boolean>(employee.roles.some(r => r === "office"))
  const [isManager, setIsManager] = useState<boolean>(employee.roles.some(r => r === "manager"))
  const [errors, setErrors] = React.useState<string[]>([])

  const save = async () => {
    await editRoles(employee.id, isOffice, isManager)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <>
    <div className="flex flex-col space-y-1">
      <label className={"flex space-x-2 border border-slate-100 bg-slate-100 rounded px-1 mx-1 my-1"}>
        <input type={"checkbox"} checked={isEmployee} disabled={true}/>
        <h1>App</h1>
      </label>
      <label
        className={"flex space-x-2 border border-slate-100 rounded px-1 mx-1 my-1 hover:cursor-pointer hover:bg-slate-100"}>
        <input type={"checkbox"} checked={isOffice} onChange={() => setIsOffice(!isOffice)}/>
        <h1>Planning</h1>
      </label>
      <label
        className={"flex space-x-2 border border-slate-100 rounded px-1 mx-1 my-1 hover:cursor-pointer hover:bg-slate-100"}>
        <input type={"checkbox"} checked={isManager} onChange={() => setIsManager(!isManager)}/>
        <h1>Beheer</h1>
      </label>
      {(isOffice != employee.roles.some(r => r === "office") || isManager != employee.roles.some(r => r === "manager")) &&
        <Button type="primary" size="xs" text="Wijzigingen opslaan" onClick={() => save()}/>
      }
    </div>
  </>

}