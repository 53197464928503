import {FC, useMemo, useState} from "react";
import {usePlanner} from "./PlannerContext";
import moment from "moment";
import {useApi} from "../api/APIContext";
import {Team, Workorder} from "../api/dto";
import {Button} from "../components/form/Button";
import {
  faArchive,
  faBoxArchive,
  faCheckSquare,
  faLocationPin,
  faPeopleGroup,
  faPerson,
  faPhone, faTrash,
  faUndo
} from "@fortawesome/free-solid-svg-icons";
import {AddOrderModal} from "../modals/AddOrderModal";
import {useModal} from "../components/layout/ModalProvider";
import {DeleteOrderModal} from "../modals/DeleteOrderModal";
import React from "react";
import {OrderAutocomplete} from "../components/form/Autocomplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {WorkorderDetailsModal} from "../modals/WorkorderDetailsModal";
import {ArchiveModal} from "../modals/ArchiveModal";
import {UnarchiveModal} from "../modals/UnarchiveModal";
import {DeleteWorkordersModal} from "../modals/DeleteWorkordersModal";
import {ProjectDetailsModal} from "../modals/ProjectDetailsModal";

export const WorkorderOverview: FC<{compact: boolean}> = (props) => {
  const {day} = usePlanner()
  const {workorders, projects, teams} = useApi()
  const teamsMap = useMemo(() => {
    return teams.reduce((acc, t) => {
      acc[t.id] = t
      return acc
    }, {} as {[key: string]: Team})
  }, [teams]);
  return <div className={"max-w-screen-2xl mx-auto"}>
    <WorkorderDayCardOverview workorders={workorders} compact={props.compact} team={teamsMap} day={moment(day)} />
  </div>
}

const WorkorderDayCardOverview: FC<{workorders: Workorder[], team: {[p:string]: Team}, compact: boolean, day: moment.Moment}> = props => {
  const [showArchived, setShowArchived] = useState<boolean>(false)
  const [showFinished, setShowFinished] = useState<boolean>(true)
  const dayOrders = props.workorders
    .filter(o => moment(o.startAt).isSame(props.day, 'day'))
  const filteredDayOrders = dayOrders
    .filter(o => (showFinished || !o.isFinished))
    .filter(o => (showArchived || !o.archivedAt))
  const nArchived = dayOrders.filter(o => o.archivedAt).length
  const nFinished = dayOrders.filter(o => o.isFinished).length
  const untouched = dayOrders.filter(o => !o.feedback || o.feedback.length === 0)
  const deleteModal = useModal({title: "Werkbonnen verwijderen", size: 'xl', body: <DeleteWorkordersModal orders={untouched} />})
  return <div className={`flex-1 ${props.compact ? 'text-sm' : ''}`}>
    <div className={'flex items-center mb-6'}>
      <div className={'flex items-center flex-1 space-x-3'}>
        <h1 className={"font-bold text-2xl"}>{props.day.toDate().toLocaleDateString('nl', {
          weekday: 'long',
          day: 'numeric',
          month: 'long'
        })}</h1>
        {dayOrders.length > 0 && <>
          <label className={"flex space-x-2 rounded border border-slate-300 px-2 py-1 hover:cursor-pointer hover:bg-slate-100"}>
            <input type={'checkbox'} checked={showFinished} onChange={() => setShowFinished(!showFinished)}/>
            <h1>Afgerond ({nFinished})</h1>
          </label>
          <label className={"flex space-x-2 rounded border border-slate-300 px-2 py-1 hover:cursor-pointer hover:bg-slate-100"}>
            <input type={'checkbox'} checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
            <h1>Gearchiveerd ({nArchived})</h1>
          </label>
        </>}
      </div>
      {untouched.length > 0 &&
        <Button type={'danger'} size={'sm'} text={'Dag opschonen'} icon={faTrash} onClick={() => {deleteModal.open()}} />
      }
    </div>
    <div className={'mt-2 mb-8 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6'}>
      {filteredDayOrders.map((workorder, i) => {
        return <WorkorderOverviewCard
          compact={props.compact}
          workorder={workorder}
          key={i}
          team={props.team[workorder.teamId ?? '']}
        />
      })}
    </div>
  </div>
}

const WorkorderOverviewCard: FC<{workorder: Workorder, team?: Team, compact: boolean}> = props => {
  const {projects} = useApi()
  const {setDay, focusTask} = usePlanner()
  const detailsModal = useModal({title: "Werkbon", size: 'xl', body: <WorkorderDetailsModal order={props.workorder} />})
  const archiveModal = useModal({title: "Archiveren", size: 'xl', body: <ArchiveModal order={props.workorder} />})
  const unarchiveModal = useModal({title: "Dearchiveren", size: 'xl', body: <UnarchiveModal order={props.workorder} />})
  const project = projects.find(p => p.projectNumber === props.workorder.projectNumber)
  const projectDetailsModal = useModal({title: `Project #${project?.projectNumber}`, size: '2xl', body: <ProjectDetailsModal focusTask={(task) => focusTask(task)} goToDay={(day) => setDay(day)} project={project!} />})
  return <div className={`bg-white px-4 py-3 border ${props.workorder.isFinished ? 'border-green-700' : props.workorder.archivedAt ? 'border-gray-400' : 'border-slate-200'} rounded relative flex flex-col items-stretch`}>
    {props.workorder.isFinished && <>
      <div
        className={`h-5 absolute -top-3 inset-x-4 bg-green-700 rounded border border-white text-white px-1 flex items-center justify-center text-xs`}>
        <FontAwesomeIcon icon={faCheckSquare} className={"mr-1"}/>
        <span className={"font-medium"}>AFGEROND</span>
      </div>
    </>}
    {props.workorder.archivedAt && <>
      <div
        className={`h-5 absolute -top-3 inset-x-4 bg-gray-500 rounded border border-white text-white px-1 flex items-center justify-center text-xs`}>
        <FontAwesomeIcon icon={faBoxArchive} className={"mr-1"}/>
        <span className={"font-medium"}>GEARCHIVEERD</span>
      </div>
    </>}
    <div className={`${props.workorder.isFinished || props.workorder.archivedAt ? 'opacity-50' : ''} flex-1`}>
      <div className={"flex items-center justify-between"}>
        <button className={`font-medium text-sm ${project ? 'text-blue-800 underline' : ''}`} disabled={!project} onClick={() => projectDetailsModal.open()}>Project #{props.workorder.projectNumber}</button>
        {props.workorder.projectDeliveryCity && <div>
          <div className={'text-sm text-blue-800 flex items-center space-x-2'}>
            <FontAwesomeIcon icon={faLocationPin}/>
            <div>{props.workorder.projectDeliveryCity}</div>
          </div>
        </div>}
      </div>
      <div className={`${props.compact ? 'text-base my-2' : 'text-lg my-3'} font-medium`}>
        {props.workorder.projectDescription ?? "Overig project"}
      </div>
      {props.workorder.quantityAmount !== null && props.workorder.quantityTypeName !== null && <div>
        {props.workorder.quantityAmount} m² {props.workorder.quantityTypeName}
      </div>}
      <div>
        {props.workorder.description}
      </div>
      {props.team && <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
        <div className={"font-medium text-sm"}>Team</div>
        <div className={"font-medium text-blue-900 ml-2"}>
          <FontAwesomeIcon icon={faPeopleGroup} className={"mr-2"}/>{props.team.name}
        </div>
      </div>}
      {!props.compact && <>
        {(props.workorder.projectRemark || props.workorder.workorderRemark) &&
          <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
            <div className={"font-medium text-sm"}>Opmerkingen</div>
            <div className={"ml-2 my-1 text-slate-700"}>
              <div>{props.workorder.workorderRemark}</div>
              <div>{props.workorder.projectRemark}</div>
            </div>
          </div>}
        {props.workorder.projectDeliveryName && <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>a
          <div className={"font-medium text-sm"}>Contactpersoon</div>
          <div className={"font-medium text-blue-900 ml-2"}><FontAwesomeIcon icon={faPerson}
                                                                             className={"mr-2"}/>{props.workorder.projectDeliveryName}
          </div>
        </div>}
        {props.workorder.projectDeliveryPhone && <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
          <div className={"font-medium text-sm"}>Contactpersoon (telefoon)</div>
          <div className={"font-medium text-blue-900 ml-2"}><FontAwesomeIcon icon={faPhone}
                                                                             className={"mr-2"}/>{props.workorder.projectDeliveryPhone}
          </div>
        </div>}
        {props.workorder.projectDeliveryAddress && props.workorder.projectDeliveryZipCode && props.workorder.projectDeliveryCity &&
          <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
            <div className={"font-medium text-sm"}>Werkadres</div>
            <div className={"font-medium text-blue-900 ml-2 flex items-center"}><FontAwesomeIcon icon={faLocationPin}
                                                                                                 className={"mr-2"}/>
              <div>
                {props.workorder.projectDeliveryAddress}<br/>
                {props.workorder.projectDeliveryZipCode},&nbsp;{props.workorder.projectDeliveryCity}
              </div>
            </div>
          </div>}
      </>}
    </div>
    <div className={"flex flex-col space-y-2"}>
      {props.workorder.isFinished && <Button type={'primary'} size={'md'} text={`Bekijk Opmerkingen & Fotos (${props.workorder.feedback?.length??0})`} onClick={() => detailsModal.open()} />}
      {(!props.workorder.isFinished && props.workorder.feedback?.length) ? <Button type={'secondary'} size={'md'} text={`Bekijk Fotos (${props.workorder.feedback?.length??0})`} onClick={() => detailsModal.open()} /> : <></>}
      {props.workorder.archivedAt ? <Button type={'secondary'} text={'Terugzetten'} icon={faUndo} size={'md'} onClick={() => unarchiveModal.open()}/> : <Button type={'primary'} size={'md'} icon={faArchive} text={'Archiveren'} onClick={() => archiveModal.open()} />}
    </div>
  </div>
}
