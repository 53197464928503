import classNames from "classnames"
import React, {Key, ReactNode, useEffect, useRef, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faCircleNotch, faSpinner, IconDefinition} from "@fortawesome/free-solid-svg-icons";

export type KeyProperties<T> = {
  [P in keyof T]: T[P] extends Key ? P : never;
}[keyof T]

export type PossibleColumns<T> = { [P in keyof T]: TableColumn<T, P> }[keyof T]

interface OnClickAction<T> {
  icon: IconDefinition|((row: T) => IconDefinition)
  variant?: 'primary' | 'secondary' | 'danger'
  text: string|((row: T) => string)
  onClick: ( row: T ) => void|Promise<void>
}

export interface BaseTableProps<T> {
  keyProperty: KeyProperties<T>
  data: T[]
  columns: Array<PossibleColumns<T>>

  placeholder: ReactNode

  /**
   * Optionally make rows clickable, this will add a chevron-right to the right
   * side of the table.
   */
  onClickRow?: (row: T) => void

  /**
   * Optionally make rows clickable, this will add an options button to the right
   * side of the table
   */
  onClickActions?: OnClickAction<T>[]

  /**
   * Additional CSS classes to add to this component.
   */
  className?: string

  /**
   * An ID reference to an element describing the contents of this table.
   * Optional but recommended.
   */
  labelledBy?: string

  /**
   * Show a loading state while fetching content.
   */
  loading?: boolean
  // eslint-disable-next-line no-unused-vars
  headerMapper?: ( props: BaseTableProps<T> ) => JSX.Element[]
  compact?: boolean
}

export interface TableProps<T> extends BaseTableProps<T> {
  /**
   * Whether to add a tab index to the wrapper around this table when
   * horizontal scrolling is needed to see all its content. This should be set
   * to true for tables that don't have tab stops in their content, since
   * otherwise keyboard users will have no way of horizontally scrolling
   * through the content. If this is enabled, ensure a label for the wrapper
   * is also provided by setting the `labelledBy` prop.
   */
  enableScrollTabIndex?: boolean
}

export interface TableColumn<T, P extends keyof T> {
  property: P
  header: string
  wrap?: boolean
  // eslint-disable-next-line no-unused-vars
  transform?: ( property: T[P], row: T ) => ReactNode
}

export function DataTable<T> ( props: TableProps<T> ): JSX.Element {
  const container = React.createRef<HTMLDivElement>()
  const xScrollable = useRef<boolean>( false )
  useEffect( () => {
    xScrollable.current =
      props.enableScrollTabIndex === true &&
      container.current !== null &&
      container.current.scrollWidth > container.current.offsetWidth
  }, [props, container] )

  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  return (
    <div
      ref={container}
      tabIndex={xScrollable.current ? 0 : undefined}
      role={xScrollable.current ? "group" : undefined}
      aria-labelledby={props.labelledBy}
      className={`-my-2 overflow-x-auto ${
        props.className || ""
      }`}
    >
      {renderTable( props, mapHeaders )}
    </div>
  )
}

export function renderEmptyState<T>( props: BaseTableProps<T> ): JSX.Element {
  return <div className={"h-32 flex items-center justify-center"}>
    {props.placeholder}
  </div>
}

export function renderTable<T> (
  props: BaseTableProps<T>,
  // eslint-disable-next-line no-unused-vars
  headerMapper: ( props: BaseTableProps<T> ) => JSX.Element[]
): JSX.Element {
  const isLoading = props.loading || false

  return (
    <div className="py-2 align-middle inline-block min-w-full">
      <div className="overflow-hidden border-2 border-slate-100 sm:rounded-lg bg-white">
        <table
          className="min-w-full"
          aria-labelledby={props.labelledBy}
        >
          <thead>
          <tr>{(props.headerMapper ?? headerMapper)( props )}</tr>
          </thead>
          <tbody className={props.compact ? 'text-sm' : ''}>
          {isLoading ? renderLoadingState( props ) : mapRows( props )}
          </tbody>
        </table>
        {!isLoading && props.data.length === 0 && renderEmptyState( props )}
      </div>
    </div>
  )
}

function mapHeaders<T> ( props: TableProps<T> ): JSX.Element[] {
  const headers = props.columns.map( ( column ) =>
    <th
      key={column.header}
      className={`${props.compact ? 'py-2 px-3' : 'py-3 px-4'} text-left uppercase text-slate-500 text-xs whitespace-no-wrap`}
    >
      {column.header}
    </th>
  )
  if (props.onClickRow || props.onClickActions) {
    headers.push(<th key={'_actions'} className={(props.compact ? 'py-2 px-3' : 'py-3 px-4') + " text-right"}></th>)
  }
  return headers
}

function mapRows<T> ( props: TableProps<T> ): JSX.Element[] {
  return props.data.map( ( row, index ) => {
    const key = row[props.keyProperty] as unknown as Key
    return (
      <tr key={key} className={`${index !== 0 && ''} bg-white cursor-pointer hover:bg-blue-50 border-t border-slate-50`} onClick={props.onClickRow !== undefined ? (() => props.onClickRow!(row)) : undefined}>
        {mapCells( row, props )}
      </tr>
    )
  } )
}

function mapCells<T> ( row: T, props: TableProps<T> ): JSX.Element[] {
  const columns = props.columns.map( ( column, index ) => {
    const spacing = props.compact ? 'py-2 px-3' : 'py-3 px-4'
    const cellClasses = classNames( {
      [`${spacing} text-left`]: true,
      "whitespace-no-wrap": column.wrap !== true,
      "font-medium ": index === 0,
      "": index !== 0,
    } )
    const cellContent = column.transform
      ? column.transform( row[column.property], row )
      : row[column.property]
    if ( index === 0 ) {
      return (
        <th key={column.header} className={cellClasses} scope="row">
          <>{cellContent}</>
        </th>
      )
    } else {
      return (
        <td key={column.header} className={cellClasses}>
          <>{cellContent}</>
        </td>
      )
    }
  } )
  if (props.onClickRow || props.onClickActions) {
    columns.push(<td key={'_action'} className={(props.compact ? 'py-2 px-3' : 'py-3 px-4') + ' text-blue-600'}>
      <div className={"flex items-center justify-end space-x-3"}>
        {props.onClickActions && props.onClickActions.map( ( action, index ) => {
          return <TableRowAction key={index} row={row} action={action} />
        })}
        {props.onClickRow && <div className={"flex items-center justify-end space-x-3"}>
          <>&nbsp;</><span className={"text-xs uppercase tracking-wide font-medium"}>Details</span> <FontAwesomeIcon icon={faChevronRight} />
        </div>}
      </div>
    </td>)
  }
  return columns
}

function TableRowAction<T> ( props: { row: T, action: OnClickAction<T> } ): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const text = typeof props.action.text === 'function' ? props.action.text( props.row ) : props.action.text
  const icon = loading ? faCircleNotch : typeof props.action.icon === 'function' ? props.action.icon( props.row ) : props.action.icon
  const buttonStyle = {
    'primary': 'bg-blue-800 text-white hover:bg-blue-700',
    'secondary': 'bg-transparent text-blue-700 hover:bg-blue-200',
    'danger': 'bg-red-800 text-white hover:bg-red-700',
  }[props.action.variant ?? 'secondary']
  const clickAction = async () => {
    setLoading(true)
    await props.action.onClick(props.row)
    setLoading(false)
  }
  return <button className={`rounded ${buttonStyle} font-medium text-sm px-2 py-1`} onClick={clickAction}>
    <FontAwesomeIcon icon={icon} spin={loading} className={'mr-2'} />
    <span>{text}</span>
  </button>
}

function renderLoadingState<T> ( props: TableProps<T> ): JSX.Element {
  return <>
    <tr className={`bg-white border-t border-slate-50`}>
      {props.columns.map( ( column, index ) => {
        return <td key={index} className={props.compact ? 'py-2 px-3' : 'py-3 px-4'}>
          <div className={"w-24 h-3 bg-slate-200 animate-pulse rounded-full"}></div>
        </td>
      })}
    </tr>
    <tr className={`bg-white border-t border-slate-50`}>
      {props.columns.map( ( column, index ) => {
        return <td key={index} className={props.compact ? 'py-2 px-3' : 'py-3 px-4'}>
          <div className={"w-24 h-3 bg-slate-200 animate-pulse rounded-full"}></div>
        </td>
      })}
    </tr>
    <tr className={`bg-white border-t border-slate-50`}>
    {props.columns.map( ( column, index ) => {
      return <td key={index} className={props.compact ? 'py-2 px-3' : 'py-3 px-4'}>
        <div className={"w-24 h-3 bg-slate-200 animate-pulse rounded-full"}></div>
      </td>
    })}
  </tr>
    <tr className={`bg-white border-t border-slate-50`}>
    {props.columns.map( ( column, index ) => {
      return <td key={index} className={props.compact ? 'py-2 px-3' : 'py-3 px-4'}>
        <div className={"w-24 h-3 bg-slate-200 animate-pulse rounded-full"}></div>
      </td>
    })}
  </tr>
  </>
}
